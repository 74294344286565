:root {
  --toastify-toast-width: 100%;
  --toastify-toast-min-height: 3rem;
  /* --toastify-toast-max-height: 3rem; */
  --toastify-font-family: Roboto, sans-serif;
  --toastify-z-index: auto;

  --toastify-color-light: #fff;
  --toastify-color-dark: #121212;
  --toastify-color-info: #3498db;
  --toastify-color-success: #c6f6d5;
  --toastify-color-warning: #f1c40f;
  --toastify-color-error: #fed7d7;
  --toastify-color-transparent: rgba(255, 255, 255, 0.7);

  --toastify-icon-color-info: var(--toastify-color-info);
  --toastify-icon-color-success: var(--toastify-color-success);
  --toastify-icon-color-warning: var(--toastify-color-warning);
  --toastify-icon-color-error: var(--toastify-color-error);

  --toastify-text-color-info: #000;
  --toastify-text-color-success: #000;
  --toastify-text-color-warning: #000;
  --toastify-text-color-error: #000;
}

.Toastify__close-button {
  position: absolute;
  right: 0;
  padding: 0.5rem;
  color: #000;
  opacity: 0.7;
  border: none;
  background: transparent;
  cursor: pointer;
  transition: opacity 0.3s ease;
  align-self: center;
}

.Toastify__toast--error .Toastify__toast-icon {
  color: #e53e3e;
}

.Toastify__toast--success .Toastify__toast-icon {
  color: #2ca05e;
}

.Toastify__toast-container {
  max-width: 1480px;
  z-index: 9999;
}
